import React, { useEffect, useState } from 'react';
import './Template.css';
import USDCLOGO from './USDC.png';
import GAMEIMAGE from './Roobet-Betting-App.png'
import Game, { currentCharacter } from './Game';
import './LoadingIcon.css'  
import { sendMessageFunction, winnings, tossNumberInSession, setTossNumberInSession, setWinnings } from './Game';
import {GlobalGlobalHistoryItems, globalSetDifficultyOverlay, globalSetPlayStyleOverlay, GlobalYourHistoryItems, treasuryId, updateCurrentPurseAmount, updateGlobalHistory, updateYourHistory} from './App';
import { createBetSession, userCashoutAndCloseSession } from './Server/GraphqlEndpointFunctions';

export let GlobalGameProbability = 0.96;
export let purseAmount:number = 0;
export let currentTreasuryRiskMaxBetAmount:number = 0;
export const setPurseAmount = (amount: number): void => {
  purseAmount = amount;
};
export const updatePurseAmount = (updateAmount: number): void => {
  purseAmount = purseAmount + updateAmount;
};
export let houseFee = 0.01;
export let currentBetAmount = 0.00;
export const changeCurrentBetAmount = (changeAmount: number): void => {
  currentBetAmount = changeAmount;
};
export let updateBetAmount: (changeAmount: number)=> void;
export let returnValuesArray:number[] = [];
export const updateReturnValuesArray = (array: number[]): void => {
  returnValuesArray = array;
};
export let updateCashOutWinnings : (cashoutWinnings: number) => void;
export let updateStartGameButtonStatus : (startGameButtonStatus: string) => void;

export let currentBetSession: string;

export let currentPlayStyle: string = 'manual';

export let currentAutoPlayStatus: string = 'NOT_STARTED'; 
export const setCurrentAutoPlayStatus = (num: string): void => {
  currentAutoPlayStatus = num;
};  

export let currentManualPlayStatus: string = 'NOT_STARTED';
export const setCurrentManualPlayStatus = (num: string): void => {
  currentManualPlayStatus = num;
};  

export let currentAutoPlaySessionTotalBetAmount: number = 0;
export const setCurrentAutoPlaySessionTotalBetAmount = (num: number): void => {
  currentAutoPlaySessionTotalBetAmount = num;
};  
export let currentAutoPlaySessionTotalAmountGained: number = 0;
export const setCurrentAutoPlaySessionTotalAmountGained = (num: number): void => {
  currentAutoPlaySessionTotalAmountGained = num;
};
export let currentAutoPlayPrevSessionTotalAmountGained: number = 0;
export const setCurrentAutoPlayPrevSessionTotalAmountGained = (num: number): void => {
  currentAutoPlayPrevSessionTotalAmountGained = num;
};
export let currentAutoPlaySessions: number = 0;
export const setCurrentAutoPlaySessions = (num: number): void => {
  currentAutoPlaySessions = num;
};
export let currentAutoPlayStopLoss: number = 0;
export let currentAutoPlayStopGain: number = 0;
export let currentAutoPlaySessionGain: number = 0;
export const setCurrentAutoPlaySessionGain = (num: number): void => {
  currentAutoPlaySessionGain = num;
};
export let globalSetRunNextAutoPlayBetSession : (status: boolean) => void;
export let globalSetRunNextTossAutoPlayBetSession : (status: boolean) => void;
export let globalSetAutoPlayStatus : (status: string) => void;
export let globalSetSessionGain : (status: number) => void;
export let globalSetManualPlayStatus : (status: string) => void;
export let globalSetShowGame : (status: boolean) => void;




interface BetAmountProps {
  betAmount: string;
  showTreasuryRiskMaxBetAmount: boolean
  handleChange: (amount: React.ChangeEvent<HTMLInputElement>) => void;
  halfBet: () => void;
  doubleBet: () => void;
  maxBet: () => void;
}

interface DifficultyProps {
  difficultyMode: string;
  setDifficultyMode: (mode: string) => void;
}

interface PlaceBetProps{
  difficultyMode: string;
  playStyle: string;
  autoPlayStatus:string;
  manualPlayStatus: string;
  setManualPlayStatus: (mode: string) => void;
  setShowTreasuryRiskMaxBetAmount: (mode: boolean) => void;
}


interface PlayerStyleProps {
  playStyle: string;
  setPlayStyle: (mode: string) => void;
  autoPlaySessionCount: number;
  handleSessionCountChange: (amount: React.ChangeEvent<HTMLInputElement>) => void;
  stopLoss: number;
  handleStopLoss: (amount: React.ChangeEvent<HTMLInputElement>) => void;
  stopGain: number;
  handleStopGain: (amount: React.ChangeEvent<HTMLInputElement>) => void;
  sessionGain: number;
  autoPlayStatus:string;
  setAutoPlayStatus: (amount: string) => void;
  runAutoPlay: () => void;
  setRunNextAutoPlayBetSession: (status: boolean) => void;
  setAutoPlaySessionCount:(status: number) => void;
  setStopLoss: (status: number) => void;
  setStopGain:(status: number) => void;
  setSessionGain:(status: number) => void;
}

const BetAmount: React.FC<BetAmountProps> = ({ betAmount, handleChange, halfBet, doubleBet, maxBet, showTreasuryRiskMaxBetAmount}) => {
  
  const [visible, setVisible] = useState(false);
  const [showAmount, setShowAmount] = useState(0);
  useEffect(() => {
    if (showTreasuryRiskMaxBetAmount) {
      setVisible(true);  // Fade in
      setShowAmount(currentTreasuryRiskMaxBetAmount);
    } else {
      setTimeout(() => setVisible(false), 1500); // Delay for fade-out duration
    }
  }, [showTreasuryRiskMaxBetAmount]);

  return(
  <div className="bet-amount">
    <div className="bet-header">bet amount
        {visible ? (
        <div className={`fade ${showTreasuryRiskMaxBetAmount ? 'show' : ''}`}>
         <span className="max-bet">~ max possible bet {showAmount}$</span>
        </div>
      ) : null}
    </div>
    <div className="bet-input">
      <img src={USDCLOGO} className="dollar-icon" />
      <input
        type="text"
        value={betAmount}
        onChange={handleChange}
        className="input-field"
      />
      <button onClick={halfBet}>1/2</button>
      <button onClick={doubleBet}>2x</button>
      <button onClick={maxBet}>max</button>
    </div>
  </div>
  );
};

const Difficulty: React.FC<DifficultyProps> = ({ difficultyMode, setDifficultyMode}) => (
  <div className="difficulty">
    <div className="difficulty-header">
      difficulty
      <button className="info-button" onClick={()=>{
        globalSetDifficultyOverlay(true)
      }}>i</button>
    </div>
    <div className="difficulty-buttons">
      <button className={`difficulty-button ${difficultyMode === "easy" ? "active" : ""}`} style={{borderTopRightRadius:0, borderBottomRightRadius:0}} onClick={()=>{
        if((currentPlayStyle == 'manual' && currentManualPlayStatus=='NOT_STARTED')  || (currentPlayStyle == 'auto' && currentAutoPlayStatus=='NOT_STARTED')){
          setDifficultyMode("easy");
        }
      }}>easy</button>
      <button className={`difficulty-button ${difficultyMode === "medium" ? "active" : ""}`} style={{borderRadius:0}} onClick={()=>{
            if((currentPlayStyle == 'manual' && currentManualPlayStatus=='NOT_STARTED')  || (currentPlayStyle == 'auto' && currentAutoPlayStatus=='NOT_STARTED')){
              setDifficultyMode("medium");
            }
      }}>medium</button>
      <button className={`difficulty-button ${difficultyMode === "hard" ? "active" : ""}`} style={{borderRadius:0}} onClick={()=>{
            if((currentPlayStyle == 'manual' && currentManualPlayStatus=='NOT_STARTED')  || (currentPlayStyle == 'auto' && currentAutoPlayStatus=='NOT_STARTED')){
                setDifficultyMode("hard");
            }
      }}>hard</button>
      <button className={`difficulty-button ${difficultyMode === "daredevil" ? "active" : ""}`} style={{borderTopLeftRadius:0, borderBottomLeftRadius:0}} onClick={()=>{
            if((currentPlayStyle == 'manual' && currentManualPlayStatus=='NOT_STARTED')  || (currentPlayStyle == 'auto' && currentAutoPlayStatus=='NOT_STARTED')){
              setDifficultyMode("daredevil");
            }
      }}>daredevil</button>
    </div>
  </div>
);

const PlaceBet: React.FC<PlaceBetProps> = ({difficultyMode, playStyle, autoPlayStatus, manualPlayStatus, setManualPlayStatus, setShowTreasuryRiskMaxBetAmount}) => {

  const [startGameButtonStatus, setStartGameButtonStatus] = useState('START_GAME');
  updateStartGameButtonStatus = setStartGameButtonStatus;
  const [cashoutWinnings, setCashoutWinnings] = useState<number>(0);
  updateCashOutWinnings = setCashoutWinnings;

  return(
    ((startGameButtonStatus == 'START_GAME' && playStyle == 'manual') || (playStyle == 'auto' && autoPlayStatus == 'PAUSED') || startGameButtonStatus=='CASHOUT_GAME')?
  <button className="place-bet-button" onClick={async ()=>{
    if(startGameButtonStatus=='START_GAME' && playStyle == 'manual' && manualPlayStatus == 'NOT_STARTED' && currentBetAmount > 0 ){
      console.log('currentBetAmount:'+currentBetAmount);
      console.log('GlobalGameProbability:'+GlobalGameProbability);

      type Difficulty = "EASY" | "MEDIUM" | "HARD" | "DAREDEVIL";
      let diffMode:Difficulty = "EASY";
      let easyDiffMode:Difficulty = "EASY";
      let mediumDiffMode:Difficulty = "MEDIUM";
      let hardDiffMode:Difficulty = "HARD";
      let dareDevilDiffMode:Difficulty = "DAREDEVIL";
      if(difficultyMode == "easy"){
         diffMode = easyDiffMode;
      }
      else if(difficultyMode == "medium"){
        diffMode = mediumDiffMode;
      }
      else if(difficultyMode == "hard"){
        diffMode = hardDiffMode;
      }
      else if(difficultyMode == "daredevil"){
        diffMode = dareDevilDiffMode;
      }

      console.log('currentCharacter:'+currentCharacter);
      let resultCreateBetSession = await createBetSession(treasuryId,currentBetAmount.toString(),diffMode,currentCharacter)
      console.log('resultCreateBetSession:'+JSON.stringify(resultCreateBetSession));

      if(resultCreateBetSession.status == 'SUCCESS'){
        currentBetSession = resultCreateBetSession.response.betSessionId;
        let multiplier = parseFloat(resultCreateBetSession.response.winMultiplier)

        for(let m=0; m<=19; m++){
          let num : number = (currentBetAmount * ((multiplier)**(m+1)));
          num = parseFloat(num.toFixed(2));
          returnValuesArray.push(num);
        }
        console.log('returnValuesArray: '+returnValuesArray);
        if(currentCharacter == "Trump"){
        sendMessageFunction("GameManager","ChooseChracter",0);
        }
        else if(currentCharacter == "Kamal"){
          sendMessageFunction("GameManager","ChooseChracter",1);
        }
        sendMessageFunction("RoadManager","SetCoinValesFromReact", JSON.stringify(returnValuesArray));
        setManualPlayStatus('STARTED');
        currentManualPlayStatus = 'STARTED';
        setStartGameButtonStatus('CASHOUT_GAME')
      }

      else if(resultCreateBetSession.status == 'ERROR' && resultCreateBetSession.error=="Invalid Bet Amount"){
        globalSetRunNextAutoPlayBetSession(false);
        globalSetRunNextTossAutoPlayBetSession(false);
        setCurrentAutoPlayStatus('STOPPED');
        globalSetAutoPlayStatus('STOPPED');
        setCurrentAutoPlaySessionTotalBetAmount(0);
        setCurrentAutoPlaySessionTotalAmountGained(0);
        setCurrentAutoPlayPrevSessionTotalAmountGained(0);
        setCurrentAutoPlaySessions(0);
        setWinnings(0); 
        changeCurrentBetAmount(0);
        setTossNumberInSession(0);
        updateBetAmount(0);
        returnValuesArray = [];
        sendMessageFunction("GameManager","RetryBtnClick");
        setStartGameButtonStatus('START_GAME');
      }

      else if(resultCreateBetSession.status == 'ERROR' && resultCreateBetSession.error.split("::")[0]=="Bet Amount exceeds Treasury Risk"){
        let maxBetAmount = resultCreateBetSession.error.split("::")[2];
        currentTreasuryRiskMaxBetAmount = parseFloat(parseFloat(maxBetAmount).toFixed(2));
        setShowTreasuryRiskMaxBetAmount(true);
        setTimeout(()=>{currentTreasuryRiskMaxBetAmount = 0;setShowTreasuryRiskMaxBetAmount(false)},2000);
        console.log('maxBetAmount'+maxBetAmount);
      }

    }

    if((startGameButtonStatus=='CASHOUT_GAME' && playStyle == 'manual' && manualPlayStatus == 'STARTED') || (playStyle == 'auto' && currentAutoPlayStatus == 'PAUSED')){

      let resultUserCashoutAndCloseSession = await userCashoutAndCloseSession(currentBetSession);
      console.log('resultUserCashoutAndCloseSession:'+JSON.stringify(resultUserCashoutAndCloseSession));

      if(resultUserCashoutAndCloseSession.status == 'SUCCESS')
      {
        //run function to rety game
        if(resultUserCashoutAndCloseSession.response.winningCashOut!=0){
          //add winnings to purse amount
          updateCashOutWinnings(0);
          updatePurseAmount(parseFloat(resultUserCashoutAndCloseSession.response.winningCashOut));
          updateCurrentPurseAmount(purseAmount);
        }

        if((playStyle == 'auto' && currentAutoPlayStatus == 'PAUSED')){
          globalSetRunNextAutoPlayBetSession(false);
          globalSetRunNextTossAutoPlayBetSession(false);
          setCurrentAutoPlayStatus('STOPPED');
          globalSetAutoPlayStatus('STOPPED');
          setCurrentAutoPlaySessionTotalBetAmount(0);
          setCurrentAutoPlaySessionTotalAmountGained(0);
          setCurrentAutoPlayPrevSessionTotalAmountGained(0);
          setCurrentAutoPlaySessions(0);
        }

        setManualPlayStatus("NOT_STARTED");
        currentManualPlayStatus = 'NOT_STARTED';
        setWinnings(0); 
        changeCurrentBetAmount(0);
        setTossNumberInSession(0);
        updateBetAmount(0);
        returnValuesArray = [];
        sendMessageFunction("GameManager","RetryBtnClick");
        setStartGameButtonStatus('START_GAME');
      }

    }

  }}>{(startGameButtonStatus=='START_GAME' && playStyle == 'manual')?"start game":(playStyle == 'auto' && autoPlayStatus == 'PAUSED')?"cashout":(startGameButtonStatus=='CASHOUT_GAME')?"cashout $"+(cashoutWinnings.toFixed(2)):null}
  </button>:null
);
};

const PlayStyle: React.FC<PlayerStyleProps> = ({ 
  playStyle, setPlayStyle, autoPlaySessionCount, 
  handleSessionCountChange, stopLoss, handleStopLoss, 
  stopGain, handleStopGain, sessionGain, autoPlayStatus, 
  setAutoPlayStatus, runAutoPlay, setRunNextAutoPlayBetSession,
  setAutoPlaySessionCount,setStopLoss,setStopGain,setSessionGain}) => (
  <div className="play-style">
    <div style={{display:'flex',flexDirection:'column', width:'35%', marginLeft:'5px'}}>
    <div className="toss-style-header">
      toss style
      <button className="info-button" onClick={()=>{
        globalSetPlayStyleOverlay(true);
      }}>i</button>
    </div>
    <div className="play-style-enclosure">
        <button className={`play-style-button ${playStyle === "manual" ? "active" : ""}`} 
        onClick={()=>{
          console.log('auto play style: '+currentAutoPlayStatus);
          if(playStyle == 'auto' && (currentAutoPlayStatus == 'NOT_STARTED'))
          {
          currentPlayStyle = 'manual';
          setPlayStyle("manual");
          setCurrentAutoPlayStatus('NOT_STARTED');
          globalSetAutoPlayStatus('NOT_STARTED');
          }}} 
        style={{borderTopRightRadius:0, borderBottomRightRadius:0}}>manual</button>
        <button className={`play-style-button ${playStyle === "auto" ? "active" : ""}`} 
        onClick={()=>{
          if(playStyle == 'manual' && (currentManualPlayStatus == 'NOT_STARTED') ){
          currentPlayStyle = 'auto'; 
          setPlayStyle("auto");}}} style={{borderTopLeftRadius:0, borderBottomLeftRadius:0}}>auto</button>
    </div>
    </div>

    {(playStyle =='auto')?
    <div style={{display:'flex', marginLeft:'40px', marginTop:'5px', flexDirection:'column', alignSelf:'center'}}>
      <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'16px', color:'white'}}>sessions</span>
      <div className="num-toss-input-1">
        <input
          type="number"
          value={autoPlaySessionCount}
          onChange={handleSessionCountChange}
          className="num-toss-input-field-1"
        />
      </div>
    </div>:null}

    {(playStyle =='auto')?
    <div style={{display:'flex', marginLeft:'40px', marginTop:'5px', flexDirection:'column', alignSelf:'center'}}>
      <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'16px', color:'white'}}>stop on loss %</span>
      <div className="num-toss-input-1">
        <input
          type="number"
          value={stopLoss}
          onChange={handleStopLoss}
          className="num-toss-input-field-1"
        />
      </div>
    </div>:null}

    {(playStyle =='auto')?
    <div style={{display:'flex', marginLeft:'40px', marginTop:'5px', flexDirection:'column', alignSelf:'center'}}>
      <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'16px', color:'white'}}>stop on gain %</span>
      <div className="num-toss-input-1">
        <input
          type="number"
          value={stopGain}
          onChange={handleStopGain}
          className="num-toss-input-field-1"
        />
      </div>
    </div>:null}

    {(playStyle =='auto')?
    <div style={{display:'flex', marginLeft:'40px', marginTop:'5px', flexDirection:'column', alignSelf:'center'}}>
      <span style={{display:'flex', alignSelf:'center', fontFamily:'Comfortaa', fontWeight:'bolder', fontSize:'16px', color:'white'}}>total gain %</span>
      <div className={`session-gain ${sessionGain < 0 ? "negative" : sessionGain ===0?"zero":"positive"}`}>
        <span 
          className={`session-gain-span ${sessionGain < 0 ? "negative" : sessionGain ===0?"zero":"positive"}`}>
            {sessionGain}
          </span>
      </div>
    </div>:null}

    {(playStyle =='auto')?
    <div style={{display:'flex', marginLeft:'40px', marginTop:'25px', flexDirection:'column', alignSelf:'center'}}>
      
      {(autoPlayStatus == 'NOT_STARTED')?
      <button className="auto-toss-pause-button" onClick={()=>{
        console.log('currentBetAmount: '+currentBetAmount);
        console.log('autoPlaySessionCount: '+autoPlaySessionCount);
                if(currentBetAmount > 0 && autoPlaySessionCount > 0){
                  setAutoPlayStatus('STARTED');
                  currentAutoPlayStatus = 'STARTED';
                  console.log('AUTOPLAY SESSION: '+currentAutoPlayStatus);
                  console.log('currentBetAmount: '+currentBetAmount);
                  console.log('autoPlaySessionCount: '+autoPlaySessionCount);
                  currentAutoPlaySessionGain = sessionGain;
                  currentAutoPlaySessions = autoPlaySessionCount
                  currentAutoPlayStopGain = stopGain;
                  currentAutoPlayStopLoss = stopLoss;
                  currentAutoPlaySessionTotalBetAmount = 0;
                  currentAutoPlaySessionTotalAmountGained = 0;
                  if(currentCharacter == "Trump"){
                    sendMessageFunction("GameManager","ChooseChracter",0);
                    }
                    else if(currentCharacter == "Kamal"){
                      sendMessageFunction("GameManager","ChooseChracter",1);
                    }
                  setRunNextAutoPlayBetSession(true);
                } 
      }}>start</button>:null}
      
      {(autoPlayStatus == 'STARTED' || autoPlayStatus == 'RESUMED')?
      <button className="auto-toss-pause-button" onClick={()=>{
                //pauseAutoTossFunction();
                setAutoPlayStatus('PAUSED');
                currentAutoPlayStatus = 'PAUSED';
      }}>pause</button>:null}

      {(autoPlayStatus == 'PAUSED')?
      <button className="auto-toss-pause-button" onClick={()=>{
                //pauseAutoTossFunction();
                setAutoPlayStatus('RESUMED');
                currentAutoPlayStatus = 'RESUMED';
      }}>resume</button>:null}

    {(autoPlayStatus == 'STOPPED')?
      <button className="auto-toss-pause-button" onClick={()=>{
                //pauseAutoTossFunction();
                setAutoPlayStatus('NOT_STARTED');
                currentAutoPlayStatus = 'NOT_STARTED';
                setAutoPlaySessionCount(1);
                setStopLoss(100.0);
                setStopGain(200.0);
                setSessionGain(0.0);
                updateBetAmount(0);
                currentAutoPlaySessionGain = 0;
                currentAutoPlaySessions = 0
                currentAutoPlayStopGain = 0;
                currentAutoPlayStopLoss = 0;
                currentAutoPlaySessionTotalBetAmount = 0;
                currentAutoPlaySessionTotalAmountGained = 0;
                currentPlayStyle="manual";
                setPlayStyle("manual");
      }}>close</button>:null}

    </div>:null}

  </div>
);

const GameControls: React.FC = () => {
  const [betAmount, setBetAmount] = useState('');
  const [showTreasuryRiskMaxBetAmount, setShowTreasuryRiskMaxBetAmount] = useState(false);
  const [difficultyMode, setDifficultyMode] = useState('easy');
  const [autoPlaySessionCount, setAutoPlaySessionCount] = useState(1);
  const [stopLoss, setStopLoss] = useState(100.0);
  const [stopGain, setStopGain] = useState(200.0);
  const [sessionGain,setSessionGain] = useState(0.0);
  const [playStyle, setPlayStyle] = useState("manual");
  const [manualPlayStatus, setManualPlayStatus] = useState("NOT_STARTED");
  globalSetManualPlayStatus = setManualPlayStatus;
  const [autoPlayStatus, setAutoPlayStatus] = useState("NOT_STARTED");
  globalSetAutoPlayStatus = setAutoPlayStatus;
  globalSetSessionGain = setSessionGain;
  const [runNextAutoPlayBetSession, setRunNextAutoPlayBetSession] = useState(false);
  globalSetRunNextAutoPlayBetSession = setRunNextAutoPlayBetSession;
  const [runNextTossAutoPlayBetSession, setRunNextTossAutoPlayBetSession] = useState(false);
  globalSetRunNextTossAutoPlayBetSession = setRunNextTossAutoPlayBetSession;

  async function runAutoPlay(){
    console.log('currentAutoPlayStatus:'+currentAutoPlayStatus);
    console.log('runNextAutoPlayBetSession: '+runNextAutoPlayBetSession);
    console.log('runNextTossAutoPlayBetSession: '+runNextTossAutoPlayBetSession);
    if(currentAutoPlayStatus == 'STARTED' || currentAutoPlayStatus == 'RESUMED'){
      if(runNextAutoPlayBetSession)
      {
        console.log("---PREV-AUTOPLAY-SESSION-STATS---------------")
        console.log("currentAutoPlaySessionTotalAmountGained: "+currentAutoPlaySessionTotalAmountGained);
        console.log("currentAutoPlayPrevSessionTotalAmountGained: "+currentAutoPlayPrevSessionTotalAmountGained);
        console.log("currentAutoPlaySessions: "+currentAutoPlaySessions);
        console.log("currentAutoPlayStopLoss: "+currentAutoPlayStopLoss);
        console.log("currentAutoPlayStopGain: "+currentAutoPlayStopGain);
        console.log("currentAutoPlaySessionGain: "+currentAutoPlaySessionGain);


        type Difficulty = "EASY" | "MEDIUM" | "HARD" | "DAREDEVIL";
        let diffMode:Difficulty = "EASY";
        let easyDiffMode:Difficulty = "EASY";
        let mediumDiffMode:Difficulty = "MEDIUM";
        let hardDiffMode:Difficulty = "HARD";
        let dareDevilDiffMode:Difficulty = "DAREDEVIL";
        if(difficultyMode == "easy"){
           diffMode = easyDiffMode;
        }
        else if(difficultyMode == "medium"){
          diffMode = mediumDiffMode;
        }
        else if(difficultyMode == "hard"){
          diffMode = hardDiffMode;
        }
        else if(difficultyMode == "daredevil"){
          diffMode = dareDevilDiffMode;
        }

        console.log('currentCharacter:'+currentCharacter);
        let resultCreateBetSession = await createBetSession(treasuryId,currentBetAmount.toString(),diffMode,currentCharacter)
        console.log('resultCreateBetSession:'+JSON.stringify(resultCreateBetSession));
  
        if(resultCreateBetSession.status == 'SUCCESS'){
          currentAutoPlaySessionTotalBetAmount = currentAutoPlaySessionTotalBetAmount + currentBetAmount;
          currentBetSession = resultCreateBetSession.response.betSessionId;
          let multiplier = parseFloat(resultCreateBetSession.response.winMultiplier)
          returnValuesArray =[];
          for(let m=0; m<=19; m++){
            let num : number = (currentBetAmount * ((multiplier)**(m+1)));
            num = parseFloat(num.toFixed(2));
            returnValuesArray.push(num);
          }
          console.log('returnValuesArray: '+returnValuesArray);
          sendMessageFunction("GameManager","RetryBtnClick");
          sendMessageFunction("RoadManager","SetCoinValesFromReact", JSON.stringify(returnValuesArray));
          setRunNextAutoPlayBetSession(false);
          setRunNextTossAutoPlayBetSession(true);
        }

        if(resultCreateBetSession.status == 'ERROR' && resultCreateBetSession.error=="Invalid Bet Amount"){
          globalSetRunNextAutoPlayBetSession(false);
          globalSetRunNextTossAutoPlayBetSession(false);
          setCurrentAutoPlayStatus('STOPPED');
          globalSetAutoPlayStatus('STOPPED');
          setCurrentAutoPlaySessionTotalBetAmount(0);
          setCurrentAutoPlaySessionTotalAmountGained(0);
          setCurrentAutoPlayPrevSessionTotalAmountGained(0);
          setCurrentAutoPlaySessions(0);
          setWinnings(0); 
          changeCurrentBetAmount(0);
          setTossNumberInSession(0);
          updateBetAmount(0);
          returnValuesArray = [];
          sendMessageFunction("GameManager","RetryBtnClick");
        }

        else if(resultCreateBetSession.status == 'ERROR' && resultCreateBetSession.error.split("::")[0]=="Bet Amount exceeds Treasury Risk"){
          let maxBetAmount = resultCreateBetSession.error.split("::")[2];
          console.log('maxBetAmount'+maxBetAmount);
          currentTreasuryRiskMaxBetAmount = parseFloat(parseFloat(maxBetAmount).toFixed(2));
          setShowTreasuryRiskMaxBetAmount(true);
          setTimeout(()=>{currentTreasuryRiskMaxBetAmount = 0;setShowTreasuryRiskMaxBetAmount(false)},3000);
          globalSetRunNextAutoPlayBetSession(false);
          globalSetRunNextTossAutoPlayBetSession(false);
          setCurrentAutoPlayStatus('STOPPED');
          globalSetAutoPlayStatus('STOPPED');
          setCurrentAutoPlaySessionTotalBetAmount(0);
          setCurrentAutoPlaySessionTotalAmountGained(0);
          setCurrentAutoPlayPrevSessionTotalAmountGained(0);
          setCurrentAutoPlaySessions(0);
          setWinnings(0); 
          changeCurrentBetAmount(0);
          setTossNumberInSession(0);
          updateBetAmount(0);
          returnValuesArray = [];
          sendMessageFunction("GameManager","RetryBtnClick");
        }

      }
      if(runNextTossAutoPlayBetSession){
        console.log("-----CURRENT-TOSS-METRICS");
        console.log("currentAutoPlaySessionTotalAmountGained: "+currentAutoPlaySessionTotalAmountGained);
        console.log("currentAutoPlayPrevSessionTotalAmountGained: "+currentAutoPlayPrevSessionTotalAmountGained);
        console.log("currentAutoPlaySessions: "+currentAutoPlaySessions);
        console.log("currentAutoPlayStopLoss: "+currentAutoPlayStopLoss);
        console.log("currentAutoPlayStopGain: "+currentAutoPlayStopGain);
        console.log("currentAutoPlaySessionGain: "+currentAutoPlaySessionGain);
        sendMessageFunction("RoadManager","CrossBtnClick","auto");
        setRunNextTossAutoPlayBetSession(false);
      }
    }
  }

  useEffect(()=>{
    runAutoPlay()
  },[runNextAutoPlayBetSession,runNextTossAutoPlayBetSession,autoPlayStatus])
  
  if(difficultyMode == 'easy'){
    GlobalGameProbability = 0.96;
  }
  else if(difficultyMode == 'medium'){
    GlobalGameProbability = 0.88;
  }
  else if(difficultyMode == 'hard'){
    GlobalGameProbability = 0.80;
  }
  else if(difficultyMode == 'daredevil'){
    GlobalGameProbability = 0.60;
  }

  const halfBet = () => {
    if((currentPlayStyle == 'manual' && currentManualPlayStatus=='NOT_STARTED') || (currentPlayStyle == 'auto' && currentAutoPlayStatus=='NOT_STARTED')){
      currentBetAmount = Math.max(convertCommaFormattedStringToNumber(betAmount) / 2, 0);
      setBetAmount(prev =>formatToMillions(Math.max(convertCommaFormattedStringToNumber(prev) / 2, 0)))
    }
  };
  const doubleBet = () => {
    if((currentPlayStyle == 'manual' && currentManualPlayStatus=='NOT_STARTED')  || (currentPlayStyle == 'auto' && currentAutoPlayStatus=='NOT_STARTED')){
      if(convertCommaFormattedStringToNumber(betAmount)*2 <= purseAmount){
        currentBetAmount = convertCommaFormattedStringToNumber(betAmount) * 2;
        setBetAmount(prev => formatToMillions(convertCommaFormattedStringToNumber(prev) * 2));
      }
    }
    else{
      if((currentPlayStyle == 'manual' && currentManualPlayStatus=='NOT_STARTED')  || (currentPlayStyle == 'auto' && currentAutoPlayStatus=='NOT_STARTED')){
        currentBetAmount = convertCommaFormattedStringToNumber(purseAmount);
        setBetAmount(formatToMillions(convertCommaFormattedStringToNumber(purseAmount)));
      }
      }
  }

  const maxBet = () => {
    if((currentPlayStyle == 'manual' && currentManualPlayStatus=='NOT_STARTED')  || (currentPlayStyle == 'auto' && currentAutoPlayStatus=='NOT_STARTED')){
      setBetAmount(formatToMillions(convertCommaFormattedStringToNumber(purseAmount)));
      currentBetAmount = convertCommaFormattedStringToNumber(purseAmount);
    }
  };

  function formatToMillions(num: number): string {
    // Round the number to two decimal places if decimals are present
    num = Math.floor(num * 100) / 100;
  
    // Convert the number to a string
    const numStr = num.toString();
  
    // Split the number into the integer and decimal parts
    const [integerPart, decimalPart] = numStr.split('.');
  
    // Initialize an empty string to store the formatted integer part
    let formattedNum = '';
  
    // Iterate over the integer part from the end
    for (let i = integerPart.length - 1; i >= 0; i--) {
      // Add the current digit to the formatted number
      formattedNum = integerPart[i] + formattedNum;
  
      // Add a comma after every three digits (except the last three)
      if (i > 0 && (integerPart.length - i) % 3 === 0) {
        formattedNum = ',' + formattedNum;
      }
    }
  
    // Append the decimal part if it exists
    if (!decimalPart) {
      formattedNum += '.00';
    } else {
      // Otherwise, append the decimal part
      formattedNum += '.' + decimalPart;
    }
    return formattedNum;
  };
  
  function convertCommaFormattedStringToNumber(input: string | number): number {
    // If the input is already a number, return it
    if (typeof input === 'number') {
      return Math.floor(input * 100) / 100;
    }
  
    // Remove commas from the string and convert it to a number
    const cleanedInput = input.replace(/[^0-9.]/g, '');
    
    // Convert the cleaned string to a number
    const result = Number(cleanedInput);
  
    // Round the number to two decimal places if decimals are present
    return Math.floor(result*100)/100;
  };



  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      console.log('value:'+value);
      console.log('convertCommaFormattedStringToNumber(value)'+parseFloat(convertCommaFormattedStringToNumber(value).toFixed(2)));
      // Ensure the value is a valid number
      if((currentPlayStyle == 'manual' && currentManualPlayStatus=='NOT_STARTED')  || (currentPlayStyle == 'auto' && currentAutoPlayStatus=='NOT_STARTED')){
        if (!isNaN(Number(convertCommaFormattedStringToNumber(value))) && value.trim() !== '') {
          if((convertCommaFormattedStringToNumber(value))<=purseAmount){  
            setBetAmount(formatToMillions((convertCommaFormattedStringToNumber(value))));
            currentBetAmount = convertCommaFormattedStringToNumber(value);
          }
          // else{
          //   setBetAmount(formatToMillions((convertCommaFormattedStringToNumber(purseAmount))));
          //   currentBetAmount = convertCommaFormattedStringToNumber(purseAmount);
          // }
        } else {
            setBetAmount(''); // Clear the input if invalid
        }
      }
  };

  const handleNumericBetAmountChange = (value: number) => {
    console.log('value:'+value);
    // Ensure the value is a valid number
    if((currentPlayStyle == 'manual' && currentManualPlayStatus=='NOT_STARTED')  || (currentPlayStyle == 'auto' && (currentAutoPlayStatus=='NOT_STARTED' || currentAutoPlayStatus=='STOPPED'))){
      if (!isNaN(Number(value))) {
        if((value)<=purseAmount){  
          setBetAmount(formatToMillions((value)));
          currentBetAmount = value;
        }
        // else{
        //   setBetAmount(formatToMillions((purseAmount)));
        //   currentBetAmount = purseAmount;
        // }
      } else {
          setBetAmount(''); // Clear the input if invalid
      }
    }
};

  updateBetAmount = handleNumericBetAmountChange;

  const handleSessionCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if(currentAutoPlayStatus == 'NOT_STARTED'){
        const value = event.target.value;
        console.log('value:'+value);
        // Ensure the value is a valid number
        setAutoPlaySessionCount(parseInt(value));
      }
  };

  const handleStopLoss = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(currentAutoPlayStatus == 'NOT_STARTED'){
      const value = event.target.value;
      console.log('value:'+value);
      // Ensure the value is a valid number
      setStopLoss(parseFloat(value));
    }
  };

  const handleStopGain = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(currentAutoPlayStatus == 'NOT_STARTED'){
      const value = event.target.value;
      console.log('value:'+value);
      // Ensure the value is a valid number
      setStopGain(parseFloat(value));
    }
  };

  return (
    <div style={{display:'flex', width:'100%', flexDirection:'column'}}>
      <div className="controls">
        <BetAmount betAmount={betAmount} handleChange={handleChange} halfBet={halfBet} doubleBet={doubleBet} maxBet={maxBet} 
         showTreasuryRiskMaxBetAmount={showTreasuryRiskMaxBetAmount}/>
        <Difficulty difficultyMode={difficultyMode} setDifficultyMode={setDifficultyMode}/>
        <PlaceBet difficultyMode={difficultyMode} playStyle={playStyle} autoPlayStatus={autoPlayStatus} manualPlayStatus={manualPlayStatus} setManualPlayStatus={setManualPlayStatus} setShowTreasuryRiskMaxBetAmount={setShowTreasuryRiskMaxBetAmount}/>
      </div>
      <PlayStyle playStyle={playStyle} setPlayStyle={setPlayStyle} 
      autoPlaySessionCount={autoPlaySessionCount} handleSessionCountChange={handleSessionCountChange} 
      stopLoss={stopLoss} handleStopLoss={handleStopLoss}
      stopGain={stopGain} handleStopGain={handleStopGain}
      sessionGain={sessionGain} autoPlayStatus={autoPlayStatus} setAutoPlayStatus={setAutoPlayStatus}
      runAutoPlay = {runAutoPlay} setRunNextAutoPlayBetSession={setRunNextAutoPlayBetSession}
      setAutoPlaySessionCount={setAutoPlaySessionCount} setStopLoss={setStopLoss}
      setStopGain={setStopGain} setSessionGain={setSessionGain}/> 
    </div>
  );

}

const GameImage: React.FC = () => {
  return(
    <div className="game-image">
      <Game/>
    </div>);
};



const Template: React.FC = () => {

  return (
    <div className="app">
      <GameImage/>
      <GameControls/>
    </div>
  );
};


export default Template;
