// Function to set a value in localStorage
export const setItemLocalStorage = (key: string, value: string): void => {
    try {
      localStorage.setItem(key, value);
    } catch (error) {
      console.error(`Failed to set item in localStorage with key "${key}":`, error);
    }
};
  
// Function to get a value from localStorage
export const getItemLocalStorage = (key: string): string | null => {
try {
    return localStorage.getItem(key);
} catch (error) {
    console.error(`Failed to get item from localStorage with key "${key}":`, error);
    return null;
}
};

// Function to remove an item from localStorage
export const removeItemLocalStorage = (key: string): void => {
try {
    localStorage.removeItem(key);
} catch (error) {
    console.error(`Failed to remove item from localStorage with key "${key}":`, error);
}
};

// Function to clear all items from localStorage
export const clearStorageLocalStorage = (): void => {
try {
    localStorage.clear();
} catch (error) {
    console.error(`Failed to clear localStorage:`, error);
}
};