import { gql } from '@apollo/client';

// Mutations
export const CREATE_USER = gql`
  mutation CreateUser($address: String!, $adminUser: Boolean) {
    createUser(address: $address, adminUser: $adminUser)
  }
`;

export const USER_BET_DEPOSIT = gql`
  mutation UserBetDeposit(
    $treasuryId: String!,
    $betDepositAmount: String!,
    $depositTransactionHash: String!
  ) {
    userBetDeposit(
      treasuryId: $treasuryId,
      betDepositAmount: $betDepositAmount,
      depositTransactionHash: $depositTransactionHash
    )
  }
`;

export const SUBSCRIBE_TO_SUPER_CHAT_MESSAGES = gql`
subscription NewSuperChatMessages( $token: String!) {
  NewSuperChatMessages(token:$token)
}
`;

export const USER_BET_WITHDRAW = gql`
  mutation UserBetWithDraw(
    $treasuryId: String!,
    $withdrawAmount: String!,
    $partiallySignedTransaction: [Int!]!
  ) {
    userBetWithDraw(
      treasuryId: $treasuryId,
      withdrawAmount: $withdrawAmount,
      partiallySignedTransaction: $partiallySignedTransaction,
    )
  }
`;

export const CREATE_USER_WITHDRAW_TRANSACTION = gql`
  mutation CreateUserWithdrawTransaction(
    $withdrawAmount: String!
  ) {
    createUserWithdrawTransaction(
      withdrawAmount: $withdrawAmount
    )
  }
`;

export const CREATE_BET_SESSION = gql`
  mutation CreateBetSession(
    $treasuryId: String!,
    $betAmount: String!,
    $difficulty: Difficulty!
    $character: String!
  ) {
    createBetSession(
      treasuryId: $treasuryId,
      betAmount: $betAmount,
      difficulty: $difficulty,
      character: $character
    )
  }
`;

export const USER_BET = gql`
  mutation UserBet($betSessionId: String!) {
    userBet(betSessionId: $betSessionId)
  }
`;

export const CREATE_SUPER_MESSAGE = gql`
  mutation createSuperChatMessage($message: String!) {
    createSuperChatMessage(message: $message)
  }
`;

export const USER_CASHOUT_AND_CLOSE_SESSION = gql`
  mutation UserCashoutAndCloseSession($betSessionId: String!) {
    userCashoutAndCloseSession(betSessionId: $betSessionId)
  }
`;

export const GET_USER_PURSE_AMOUNT = gql`
  query GetUserPurseAmount {
    getUserPurseAmount
  }
`;

export const GET_USER_BET_HISTORY = gql`
  query GetUserBetHistory {
    getUserBetHistory
  }
`;

export const GET_GLOBAL_BET_HISTORY = gql`
  query GetGlobalBetHistory {
    getGlobalBetHistory
  }
`;

export const GET_TREASURY_DEPOSIT_RETURNS = gql`
  query GetTreasuryDepositReturns {
    getTreasuryDepositReturns
  }
`;

export const GET_LATEST_SUPER_CHAT_MESSAGES = gql`
  query GetLatestSuperChatMessages {
    getLatestSuperChatMessages
  }
`;