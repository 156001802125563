import {
  CREATE_USER,
  USER_BET_DEPOSIT,
  USER_BET_WITHDRAW,
  CREATE_BET_SESSION,
  USER_BET,
  USER_CASHOUT_AND_CLOSE_SESSION,
  GET_USER_PURSE_AMOUNT,
  GET_USER_BET_HISTORY,
  GET_GLOBAL_BET_HISTORY,
  GET_TREASURY_DEPOSIT_RETURNS,
  CREATE_USER_WITHDRAW_TRANSACTION,
  CREATE_SUPER_MESSAGE,
  GET_LATEST_SUPER_CHAT_MESSAGES
} from './GraphqlEndpointConstants'; // Adjust the path as necessary

import client from './ApolloClient';

export const createUser = async (address: string, adminUser?: boolean) => {
    try {
      const { data } = await client.mutate({
        mutation: CREATE_USER,
        variables: { address, adminUser },
        fetchPolicy:'network-only'
      });
      return data.createUser;
    } catch (error) {
      console.error('Error creating user:', error);
      throw error;
    }
  };
  
  export const userBetDeposit = async (treasuryId: string, betDepositAmount: string, depositTransactionHash: string) => {
    
    console.log("treasuryId:",treasuryId);
    console.log("betDepositAmount:",betDepositAmount);
    console.log("depositTransactionHash:",depositTransactionHash);
    try {
      const { data } = await client.mutate({
        mutation: USER_BET_DEPOSIT,
        variables: { treasuryId, betDepositAmount, depositTransactionHash },
        fetchPolicy:'network-only'
      });
      return data.userBetDeposit;
    } catch (error) {
      console.error('Error depositing bet:', error);
      throw error;
    }
  };
  
  export const userBetWithdraw = async (treasuryId: string, withdrawAmount: string, partiallySignedTransaction: Array<Number>) => {
    try {
      const { data } = await client.mutate({
        mutation: USER_BET_WITHDRAW,
        variables: { treasuryId, withdrawAmount, partiallySignedTransaction},
        fetchPolicy:'network-only'
      });
      return data.userBetWithDraw;
    } catch (error) {
      console.error('Error withdrawing bet:', error);
      throw error;
    }
  };

  export const createUserWithdrawTransaction = async (withdrawAmount: string) => {
    try {
      const { data } = await client.mutate({
        mutation: CREATE_USER_WITHDRAW_TRANSACTION,
        variables: {withdrawAmount},
        fetchPolicy:'network-only'
      });
      return data.createUserWithdrawTransaction;
    } catch (error) {
      console.error('Error withdrawing bet:', error);
      throw error;
    }
  };
  
  export const createBetSession = async (treasuryId: string, betAmount: string, difficulty: 'EASY' | 'MEDIUM' | 'HARD' | 'DAREDEVIL', character: string) => {
    try {
      const { data } = await client.mutate({
        mutation: CREATE_BET_SESSION,
        variables: { treasuryId, betAmount, difficulty, character },
        fetchPolicy:'network-only'
      });
      return data.createBetSession;
    } catch (error) {
      console.error('Error creating bet session:', error);
      throw error;
    }
  };
  
  export const userBet = async (betSessionId: string) => {
    try {
      const { data } = await client.mutate({
        mutation: USER_BET,
        variables: { betSessionId },
        fetchPolicy:'network-only'
      });
      return data.userBet;
    } catch (error) {
      console.error('Error betting:', error);
      throw error;
    }
  };
  
  export const userCashoutAndCloseSession = async (betSessionId: string) => {
    try {
      const { data } = await client.mutate({
        mutation: USER_CASHOUT_AND_CLOSE_SESSION,
        variables: { betSessionId },
        fetchPolicy:'network-only'
      });
      return data.userCashoutAndCloseSession;
    } catch (error) {
      console.error('Error cashing out and closing session:', error);
      throw error;
    }
  };

  export const createSuperChatMessage = async (message: string) => {
    try {
      console.log('messsage in createSuperChatMessage',message);
      const { data } = await client.mutate({
        mutation: CREATE_SUPER_MESSAGE,
        variables: { message },
        fetchPolicy:'network-only'
      });
      return data.createSuperChatMessage;
    } catch (error) {
      console.error('Error createSuperChatMessage: ', error);
      throw error;
    }
  };
  
  export const getUserPurseAmount = async () => {
    try {
      const { data } = await client.query({
        query: GET_USER_PURSE_AMOUNT,
        fetchPolicy:'network-only',
      });
      return data.getUserPurseAmount;
    } catch (error) {
      console.error('Error getting user purse amount:', error);
      throw error;
    }
  };
  
  export const getUserBetHistory = async () => {
    try {
      const { data } = await client.query({
        query: GET_USER_BET_HISTORY,
        fetchPolicy:'network-only'
      });
      return data.getUserBetHistory;
    } catch (error) {
      console.error('Error getting user bet history:', error);
      throw error;
    }
  };
  
  export const getGlobalBetHistory = async () => {
    try {
      const { data } = await client.query({
        query: GET_GLOBAL_BET_HISTORY,
        fetchPolicy:'network-only'
      });
      return data.getGlobalBetHistory;
    } catch (error) {
      console.error('Error getting global bet history:', error);
      throw error;
    }
  };
  
  export const getTreasuryDepositReturns = async () => {
    try {
      const { data } = await client.query({
        query: GET_TREASURY_DEPOSIT_RETURNS,
        fetchPolicy:'network-only'
      });
      return data.getTreasuryDepositReturns;
    } catch (error) {
      console.error('Error getting treasury deposit returns:', error);
      throw error;
    }
  };

  export const getLatestSuperChatMessages = async () => {
    try {
      const { data } = await client.query({
        query: GET_LATEST_SUPER_CHAT_MESSAGES,
        fetchPolicy:'network-only'
      });
      return data.getLatestSuperChatMessages;
    } catch (error) {
      console.error('Error getLatestSuperChatMessages:', error);
      throw error;
    }
  };