import { Console } from "console";
import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { GlobalGameProbability,returnValuesArray, purseAmount, updatePurseAmount, changeCurrentBetAmount, currentBetAmount, updateCashOutWinnings,updateStartGameButtonStatus, updateReturnValuesArray, currentPlayStyle, currentAutoPlayStatus, currentAutoPlaySessionTotalAmountGained, setCurrentAutoPlaySessionTotalAmountGained, currentAutoPlaySessionGain, currentAutoPlayPrevSessionTotalAmountGained, setCurrentAutoPlayPrevSessionTotalAmountGained, setCurrentAutoPlaySessions, currentAutoPlaySessions, globalSetRunNextAutoPlayBetSession, currentAutoPlaySessionTotalBetAmount, setCurrentAutoPlaySessionGain, currentAutoPlayStopGain, currentAutoPlayStopLoss, globalSetRunNextTossAutoPlayBetSession, globalSetAutoPlayStatus, setCurrentAutoPlayStatus, setCurrentAutoPlaySessionTotalBetAmount, globalSetSessionGain, setCurrentManualPlayStatus, globalSetManualPlayStatus } from "./Template";
import { GlobalGlobalHistoryItems, GlobalYourHistoryItems, updateCurrentPurseAmount, updateGlobalHistory, updateYourHistory } from "./App";
import { updateBetAmount } from "./Template";
import { userBet, userCashoutAndCloseSession } from "./Server/GraphqlEndpointFunctions";
import { currentBetSession } from "./Template";

export let sendMessageFunction : (gameObjectName: string, methodName: string, parameter?: any) => void;
export let winnings: number = 0;
export const setWinnings = (amount: number): void => {
  winnings = amount;
};
export let tossNumberInSession: number = 0;
export const setTossNumberInSession = (count: number): void => {
  tossNumberInSession = count;
};

let latestUserBetResult:any  = null;
let previousTossStatus:string;

export let currentCharacter: string = "Trump";

const Game: React.FC = () => {
    const [character, setCharacter] = useState("Trump");
    const [tossStatus, setTossStatus] = useState("DORMANT");
    const [showGame, setShowGame] = useState(false);
    console.log("tossStatus: "+tossStatus);
      // We'll use a state to store the device pixel ratio.
    const [devicePixelRatio, setDevicePixelRatio] = useState(
        window.devicePixelRatio
    );

    useEffect(
        function () {
          // A function which will update the device pixel ratio of the Unity
          // Application to match the device pixel ratio of the browser.
          const updateDevicePixelRatio = function () {
            setDevicePixelRatio(window.devicePixelRatio);
          };
          // A media matcher which watches for changes in the device pixel ratio.
          const mediaMatcher = window.matchMedia(
            `screen and (resolution: ${devicePixelRatio}dppx)`
          );
          // Adding an event listener to the media matcher which will update the
          // device pixel ratio of the Unity Application when the device pixel
          // ratio changes.
          mediaMatcher.addEventListener("change", updateDevicePixelRatio);
          return function () {
            // Removing the event listener when the component unmounts.
            mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
          };
        },
        [devicePixelRatio]
    );

    const { unityProvider, addEventListener, removeEventListener, sendMessage  } =
      useUnityContext({
      loaderUrl: "Build/AppBuild.loader.js",
      dataUrl: "Build/AppBuild.data",
      frameworkUrl: "Build/AppBuild.framework.js",
      codeUrl: "Build/AppBuild.wasm",
      });

    sendMessageFunction = sendMessage;

    

    const handleCharacterSelection = useCallback((...parameters: any[]) => {
        const character = parameters[0] as string; // Cast to string
        if(character == "Trump" || character == "Kamal"){
          console.log("CHARACTER_SELECTED:"+character);
          setCharacter(character);
          currentCharacter = character;
        }
    }, []);

    const handleCrossClicked = (...parameters: any[]) => {
        const tossStatus = parameters[0] as string; // Cast to string
        console.log("TOSS_STATUS:"+tossStatus);
        if(tossStatus == "WAITING_TOSS_RESULT"){
          console.log("CROSS_CLICKED");
          console.log('tossStatus:'+tossStatus);
          setTossStatus("AWAITING_TOSS");
          previousTossStatus = "WAITING_TOSS_RESULT";
        }
        else if(tossStatus == 'TOSS_COMPLETED')
        {
          updateTossResult();
          previousTossStatus = 'TOSS_COMPLETED';
        }
    };

    const handleGameLoader = (...parameters: any[]) => {
      const gameLoadStatus = parameters[0] as string; // Cast to string
      if(gameLoadStatus == "STARTED"){
        console.log("GAME_STARTED");
        setShowGame(true);
      }
  };

    async function updateTossResult(){
      if(latestUserBetResult.status == 'SUCCESS'){
        if(latestUserBetResult.response.result == 'GAINED')
        {
          winnings = parseFloat(latestUserBetResult.response.winAmount);
          if(currentPlayStyle == 'auto' ){
            setCurrentAutoPlaySessionTotalAmountGained(currentAutoPlayPrevSessionTotalAmountGained + parseFloat(latestUserBetResult.response.winAmount));
            setCurrentAutoPlaySessionGain(((currentAutoPlaySessionTotalAmountGained - currentAutoPlaySessionTotalBetAmount)/(currentAutoPlaySessionTotalBetAmount))*100);
            globalSetSessionGain(parseFloat(currentAutoPlaySessionGain.toFixed(2)));
            if(tossNumberInSession == 19 ||  currentAutoPlaySessionGain >= currentAutoPlayStopGain || currentAutoPlaySessionGain <= (-1*currentAutoPlayStopLoss)){
              let resultUserCashoutAndCloseSession = await userCashoutAndCloseSession(currentBetSession);
              console.log('resultUserCashoutAndCloseSession:'+JSON.stringify(resultUserCashoutAndCloseSession));
              if(resultUserCashoutAndCloseSession.status == 'SUCCESS')
                {
                //update purseAmount with winnings  
                  updatePurseAmount(winnings-currentBetAmount);
                  updateCurrentPurseAmount(purseAmount);
                  winnings = 0;
                  updateCashOutWinnings(winnings);
                  tossNumberInSession = 0;
                  updateReturnValuesArray([]);
                  setCurrentAutoPlayPrevSessionTotalAmountGained(currentAutoPlayPrevSessionTotalAmountGained + parseFloat(latestUserBetResult.response.winAmount));
                  setCurrentAutoPlaySessions(currentAutoPlaySessions - 1);
                  if(currentAutoPlaySessions != 0 && currentAutoPlaySessionGain < currentAutoPlayStopGain && currentAutoPlaySessionGain > (-1*currentAutoPlayStopLoss)){
                    globalSetRunNextAutoPlayBetSession(true);
                    globalSetRunNextTossAutoPlayBetSession(false);
                  }
                  else{
                    changeCurrentBetAmount(0);
                    updateBetAmount(0);
                    globalSetRunNextAutoPlayBetSession(false);
                    globalSetRunNextTossAutoPlayBetSession(false);
                    setCurrentAutoPlayStatus('STOPPED');
                    globalSetAutoPlayStatus('STOPPED');
                    setCurrentAutoPlaySessionTotalBetAmount(0);
                    setCurrentAutoPlaySessionTotalAmountGained(0);
                    setCurrentAutoPlayPrevSessionTotalAmountGained(0);
                    setCurrentAutoPlaySessions(0);
                    sendMessageFunction("GameManager","RetryBtnClick");
                  }
                }
                setTossStatus('DORMANT');
            }
            else{
              tossNumberInSession = tossNumberInSession + 1;
              setTossStatus('DORMANT');
              globalSetRunNextTossAutoPlayBetSession(true);
            }
          }
          else{
            updateCashOutWinnings(winnings);
            if(tossNumberInSession == 19){

              let resultUserCashoutAndCloseSession = await userCashoutAndCloseSession(currentBetSession);
              console.log('resultUserCashoutAndCloseSession:'+JSON.stringify(resultUserCashoutAndCloseSession));
        
              if(resultUserCashoutAndCloseSession.status == 'SUCCESS')
              {
              //update purseAmount with winnings  
                updatePurseAmount(winnings-currentBetAmount);
                updateCurrentPurseAmount(purseAmount);
                winnings = 0;
                updateCashOutWinnings(winnings);
                tossNumberInSession = 0;
                updateReturnValuesArray([]);
                globalSetManualPlayStatus('NOT_STARTED');
                setCurrentManualPlayStatus('NOT_STARTED');
                changeCurrentBetAmount(0);
                updateBetAmount(0);
                setTimeout(()=>{sendMessageFunction("GameManager","RetryBtnClick");},1500);
                updateStartGameButtonStatus('START_GAME');
              }
              setTossStatus('DORMANT');
            }
            else{
              tossNumberInSession = tossNumberInSession + 1;
              setTossStatus('DORMANT');
            }
          }
        }
        else if(latestUserBetResult.response.result == 'LOST'){
          if(currentPlayStyle == 'auto' ){
            console.log('CURRENT BET SESSION LOST');
            setCurrentAutoPlaySessionTotalAmountGained(currentAutoPlayPrevSessionTotalAmountGained);
            setCurrentAutoPlaySessionGain(((currentAutoPlaySessionTotalAmountGained - currentAutoPlaySessionTotalBetAmount)/(currentAutoPlaySessionTotalBetAmount))*100);
            globalSetSessionGain(parseFloat(currentAutoPlaySessionGain.toFixed(2)));
            setCurrentAutoPlayPrevSessionTotalAmountGained(currentAutoPlayPrevSessionTotalAmountGained);
            setCurrentAutoPlaySessions(currentAutoPlaySessions - 1);
            console.log('currentAutoPlaySessionGain',currentAutoPlaySessionGain);
            console.log('currentAutoPlayPrevSessionTotalAmountGained',currentAutoPlayPrevSessionTotalAmountGained);
            console.log('currentAutoPlaySessions',currentAutoPlaySessions);
            console.log('currentAutoPlaySessionTotalAmountGained',currentAutoPlaySessionTotalAmountGained);
            winnings = 0;
            updateCashOutWinnings(winnings);
            tossNumberInSession = 0;
            //update purseAmount
            updatePurseAmount((-1.00*currentBetAmount));
            updateCurrentPurseAmount(purseAmount);
            updateReturnValuesArray([]);
            // sendMessageFunction("GameManager","RetryBtnClick");
            if(currentAutoPlaySessions != 0 && currentAutoPlaySessionGain < currentAutoPlayStopGain && currentAutoPlaySessionGain > (-1*currentAutoPlayStopLoss)){
              console.log('CREATING NEXT BET SESSION');
              setTimeout(()=>{
                globalSetRunNextTossAutoPlayBetSession(false);
                globalSetRunNextAutoPlayBetSession(true);
              },1500);
            }
            else{
              changeCurrentBetAmount(0);
              updateBetAmount(0);
              globalSetRunNextAutoPlayBetSession(false);
              globalSetRunNextTossAutoPlayBetSession(false);
              setCurrentAutoPlayStatus('STOPPED');
              globalSetAutoPlayStatus('STOPPED');
              setCurrentAutoPlaySessionTotalBetAmount(0);
              setCurrentAutoPlaySessionTotalAmountGained(0);
              setCurrentAutoPlayPrevSessionTotalAmountGained(0);
              setCurrentAutoPlaySessions(0);
              setTimeout(()=>{
              sendMessageFunction("GameManager","RetryBtnClick");},1500);
            }
            setTossStatus('DORMANT');
          }
          else{
            winnings = 0;
            updateCashOutWinnings(winnings);
            tossNumberInSession = 0;
            //update purseAmount
            updatePurseAmount((-1.00*currentBetAmount));
            updateCurrentPurseAmount(purseAmount);
            updateReturnValuesArray([]);
            globalSetManualPlayStatus('NOT_STARTED');
            setCurrentManualPlayStatus('NOT_STARTED');
            changeCurrentBetAmount(0);
            updateBetAmount(0);
            updateStartGameButtonStatus('START_GAME');
            setTimeout(()=>{sendMessageFunction("GameManager","RetryBtnClick");},1500);
            setTossStatus('DORMANT');
          }
        }
        latestUserBetResult.status = 'ERROR';
      }
  }


    useEffect(() => {
        addEventListener("CharacterSelection", handleCharacterSelection);
        addEventListener("CrossClicked", handleCrossClicked);
        addEventListener("GameLoadedTrigger", handleGameLoader);
        return () => {
            removeEventListener("CharacterSelection", handleCharacterSelection);
            removeEventListener("CrossClicked", handleCrossClicked);
            removeEventListener("GameLoadedTrigger", handleGameLoader);
        };
    }, [addEventListener, removeEventListener, handleCharacterSelection]);

    useEffect(()=>{
      if(tossStatus == 'AWAITING_TOSS'){
        tossCoin();
      }
    },[tossStatus])
    


    async function tossCoin(){

        let resultUserBet = await userBet(currentBetSession);
        latestUserBetResult = resultUserBet;
        console.log('resultUserBet:'+JSON.stringify(resultUserBet));
        let coinTossResult= null;
        if(resultUserBet.status == 'SUCCESS'){
          if(resultUserBet.response.result == 'GAINED')
          {
            coinTossResult = true;
            sendMessage("RoadManager","ReceiveCoinTossResult", JSON.stringify(coinTossResult));
          }

          else if(resultUserBet.response.result == 'LOST'){
            coinTossResult = false;
            sendMessage("RoadManager","ReceiveCoinTossResult", JSON.stringify(coinTossResult));
          }
        }
    }

    // function setTossReturnValues(){
    //     sendMessage("RoadManager","SetCoinValesFromReact", JSON.stringify(returnValuesArray));
    // }

      return(
        <div style={{display:'flex', width:'100%', height:"100%", alignSelf:'center', justifyContent:'center', position:"relative"}}>
        {(showGame == false)?
        <div style={{display:'flex', width:'100%', height:"100%", alignSelf:'center', justifyContent:'center', position:"absolute", zIndex:3, backgroundColor:'#191a39'}}>
          <LoadingIcon/>
        </div>
        :null}
        <Unity unityProvider={unityProvider} 
        style={{display:'flex', width:"60%", height:'100%', alignSelf:'center'}} 
        devicePixelRatio={devicePixelRatio}/>
        </div>
      );

}

const LoadingIcon: React.FC = () => {
  return (
    <div className="loading-icon">
      <div className="spinner" style={{width:'30px', height:'30px', borderWidth:'10px'}}/>
    </div>
  );
};


export default Game;



